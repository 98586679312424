import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide77/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Cómo automatizar el seguimiento de incidencias en centros de atención al cliente
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/seguimiento-incidencias-centros-atencion-cliente/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce cómo automatizar el seguimiento de incidencias en tu centro de atención al cliente y optimiza la gestión para mejorar resultados."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo automatizar el seguimiento de incidencias en centros de atención al cliente"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/seguimiento-incidencias-centros-atencion-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia77.jpg`}
      />
      <meta
        property="og:description"
        content="Conoce cómo automatizar el seguimiento de incidencias en tu centro de atención al cliente y optimiza la gestión para mejorar resultados."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Atención al Cliente"
      location={location}
      typeGuide="guide77"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
