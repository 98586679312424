import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide77/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide77/image/img.png"
import slider1 from "@components/pageGuide/guides/guide77/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide77/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide77/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Atención al Cliente ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo automatizar el seguimiento de incidencias en centros de atención al cliente
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La automatización es clave para gestionar el seguimiento de incidencias de manera eficiente. Organiza los reportes en un sistema que asigne prioridades y distribuya tareas según el tipo de solicitud. Configura notificaciones automáticas para mantener informados a clientes y agentes sobre el estado de cada caso.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Importancia de automatizar el seguimiento de incidencias.",
      },
      {
        key: 2,
        text: "Retos del seguimiento manual de incidencias."
      },
      {
        key: 3,
        text: "Cómo implementar la automatización de incidencias.",
      },
      {
        key: 4,
        text: "Beneficios a largo plazo de la automatización.",
      },
      {
        key: 5,
        text: "Cómo integrar herramientas que registren cada interacción.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo integrar herramientas que registren cada interacción y optimicen tiempos de respuesta. Utiliza plataformas que generen informes sobre tiempos de resolución y carga de trabajo.
          <br /><br />
          Por último, accede a más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Mejora la gestión del seguimiento de incidencias.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
